/**
 * Obiekt przechowujący implementację logiki modułu "Rules for parameters"
 */
//TODO wyciąć z ingrwd
var RulesForParameters = {
    // Nazwa storage, z którego korzystamy
    STORAGE_NAME : "rules_for_parameters",
    SESSION_STORAGE_CODE : "SESSION_STORAGE",
    // Słowniki wykorzystywane przez aplikację
    ruleElementTypes : null,
    ruleAttributeTypes : null,
    ruleRelationalOperators : null,
    ruleActionTypes : null,
    ruleActionTypeArguments : null,
    // Oznaczone elementy
    markedElements : [],
    // Parametry zebrane w trakcie sesji
    parameters : null,
    // Flaga oznaczająca czy przeglądarka wspiera sessionStorage
    storageSupported : null,
    // Wstawienie do mapy parametru (lub nadpisanie istniejącego)
    insertToParameterMap : function(pair) {
        var p = pair.split("=");
        if (p[0] === '') {
            return;
        }
        var parameter = {key:p[0], value:p[1]};
        for (var i = 0; i < RulesForParameters.parameters.length; i++) {
            if (RulesForParameters.parameters[i].key === parameter.key) {
                RulesForParameters.parameters.splice(i, 1);
            }
        }
        RulesForParameters.parameters.push(parameter);
    },
    // Pobranie z sessionStorage zapisanych parametrów, zaktualizowanie ich tymi,
    // które przyszły w urlu i zapisanie wyniku z powrotem do sessionStorage
    updateSessionStorage : function() {
        var storedParameters = RulesForParameters.getStorageItem();
        if (storedParameters === undefined || storedParameters === null ) {
            storedParameters = '';
        }

        RulesForParameters.parameters = new Array();

        $.map(storedParameters.split(';'), RulesForParameters.insertToParameterMap);
        $.map(window.location.search.substring(1).split("&"), RulesForParameters.insertToParameterMap);

        RulesForParameters.setStorageItem($.map(RulesForParameters.parameters, function(element) {
            return element.key + '=' + element.value;
        }).join(';'));
    },
    // Sprawdzenie warunku reguły, warunek przyjeżdża w Jsonie
    getCondition : function(conditionJson) {
        var condition = JSON.parse(conditionJson);
        var parameterName = condition.parameterName;
        var parameterValue = condition.parameterValue;
        var relationalOperator = RulesForParameters.findInDictById(RulesForParameters.ruleRelationalOperators,
            condition.ruleRelationalOperatorId);
        //wyszukiwanie parametrów o odpowiednich nazwach
        var parameterNameSplit = parameterName.split('*');
        var parameterNameRegex = new RegExp(parameterNameSplit.join('.*'), "g");
        RulesForParameters.updateSessionStorage();
        var storedParameters = new Array();
        for (var i = 0; i < RulesForParameters.parameters.length; i++) {
            var parameter = RulesForParameters.parameters[i];
            if (parameter.key.match(parameterNameRegex)) {
                storedParameters.push(parameter);
            }
        }
        // obsługa sytuacji, w której jest warunek "Nie istnieje parametr ..."
        if (condition.exists === false) {
            if (RulesForParameters.parameters.filter(function(parameter) {
                if (parameter.key === parameterName) {
                    var parameterValueSplit = parameterValue.split('*');
                    var parameterValueRegex = new RegExp(parameterValueSplit.join('.*'), "g");
                    for (var i = 0; i < storedParameters.length; i++) {
                        if (storedParameters[i].value && storedParameters[i].value.match(parameterValueRegex)) {
                            return true;
                        }
                    }
                }
                return false;
            }).length > 0) {
                return false;
            } else {
                return true;
            }
        }

        switch (relationalOperator.code) {
            case "CONTAINS":
                var parameterValueSplit = parameterValue.split('*');
                var parameterValueRegex = new RegExp(parameterValueSplit.join('.*'), "g");
                for (var i = 0; i < storedParameters.length; i++) {
                    if (storedParameters[i].value && storedParameters[i].value.match(parameterValueRegex)) {
                        return true;
                    }
                }
                break;
            case "DOES_NOT_CONTAIN":
                for (var i = 0; i < storedParameters.length; i++) {
                    if (storedParameters[i].value && storedParameters[i].value.indexOf(parameterValue) === -1) {
                        return true;
                    }
                }
                break;
            case "EQUALS":
            default:
                var parameterValueSplit = parameterValue.split('*');
                var parameterValueRegex = new RegExp('^' + parameterValueSplit.join('.*') + '$', "g");
                for (var i = 0; i < storedParameters.length; i++) {
                    if (storedParameters[i].value && storedParameters[i].value.match(parameterValueRegex)) {
                        return true;
                    }
                }
                break;
        }
        return false;
    },
    //Wykonanie akcji na elemencie SessionStorage
    handleSessionStorageAction : function(actionJson, parameterNames) {
        var action = JSON.parse(actionJson);
        var actionType = RulesForParameters.findInDictById(RulesForParameters.ruleActionTypes, action.ruleAction.ruleActionTypeId);
        var ruleActionArguments = action.ruleActionArgumentsViews;
        // Implementacja poszczególnych typów akcji
            switch (actionType.code) {
                case 'add_parameter':
                    var addName = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var addValue = RulesForParameters.getParameterValue(ruleActionArguments[1].ruleActionArgumentValue);
                    RulesForParameters.insertToParameterMap(addName + "=" + addValue);
                break;
                case 'remove_parameter':
                    var removeName = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var storedParameters = RulesForParameters.getStorageItem();
                    if (storedParameters === undefined || storedParameters === null ) {
                        storedParameters = '';
                    }
                    RulesForParameters.parameters = new Array();

                    $.map(storedParameters.split(';').filter(function(e){
                        return e.split["="][0] !== removeName;
                    }), RulesForParameters.insertToParameterMap);

                    RulesForParameters.setStorageItem($.map(RulesForParameters.parameters, function(element) {
                        return element.key + '=' + element.value;
                    }).join(';'));
                break;
                case 'change_parameter_value':
                    var changeName = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var changeValue = RulesForParameters.getParameterValue(ruleActionArguments[1].ruleActionArgumentValue);
                    RulesForParameters.insertToParameterMap(changeName + "=" + changeValue)
                break;
                case 'change_parameter_name':
                    var originalName = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var changeName = RulesForParameters.getParameterValue(ruleActionArguments[1].ruleActionArgumentValue);
                    //zapisujemy wartość parametru o starej nazwie
                    var value = RulesForParameters.parameters.filter(function(parameter) {
                        return parameter.key === originalName;
                    }).value;
                    //usuwamy stary parametr
                    RulesForParameters.parameters = new Array();
                    $.map(storedParameters.split(';').filter(function(e){
                        return e.split["="][0] !== originalName;
                    }), RulesForParameters.insertToParameterMap);
                    RulesForParameters.setStorageItem($.map(RulesForParameters.parameters, function(element) {
                        return element.key + '=' + element.value;
                    }).join(';'));
                    //dodajemy nowy do mapy
                    RulesForParameters.insertToParameterMap(changeName + "=" + value);
                break;
                case 'add_string':
                    var appendText = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    RulesForParameters.setStorageItem(RulesForParameters.getStorageItem() + appendText);
                break;
                case 'replace_string':
                    var toReplace = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var replaced = RulesForParameters.getParameterValue(ruleActionArguments[1].ruleActionArgumentValue);
                    RulesForParameters.setStorageItem(RulesForParameters.getStorageItem().replace(toReplace,replaced));
                break;
                case 'copy_parameter':
                    var parameterName = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var parameter = RulesForParameters.parameters.filter(function(parameter) {
                        return parameter.key === parameterName;
                    })[0];

                    $.map(window.location.search.substring(1).split("&").filter(function(parameter) {
                        return parameter.split[0] === parameterName;
                    }), RulesForParameters.insertToParameterMap);

                break;
                default:
                    console.log('Wrong Action defined in RulesForParameters Module');
                break;
            }
    },
    /**
     * Wykonanie akcji reguły, akcja przyjeżdża w Jsonie
     * @param actionJson - json z opisem akcji
     * @param elements - elementy, na których ma być wykonana akcja
     * @param parameterNames - nazwy parametrów w URL/sessionStorage, które powodują wykonanie akcji
     * (parametr ten brany jest pod uwagę podczas czyszczenia zawartości sessionStorage)
     */
    getAction : function(actionJson, elements, parameterNames) {
        var action = JSON.parse(actionJson);
        var actionType = RulesForParameters.findInDictById(RulesForParameters.ruleActionTypes, action.ruleAction.ruleActionTypeId);
        if (actionType.code === 'clean_session_storage') {
            RulesForParameters.handleSessionStorageCleaning(parameterNames);
            return;
        }
        var ruleActionArguments = action.ruleActionArgumentsViews;
        // Jeżeli podany attribute to "class" lub "id" to podmieniamy attrybut href lub src,
        // w zależności od tego, który jest obecny w elemencie. Jeżeli jest i src i href (chociaż to wątpliwe),
        // to wybieramy href
        for (var j = 0; j < elements.length; j++) {
            var element = elements[j];
            if (element === RulesForParameters.SESSION_STORAGE_CODE) {
                //Jeżeli akcja ma być wywoływana na elemencie SessionStorage to ma ona trochę inną implementację.
                RulesForParameters.handleSessionStorageAction(actionJson, parameterNames);
                continue;
            }
            //opakowujemy w jQuery dla wygody
            element = $(element);
            var attribute = "";
            if (element.prop('tagName') === "A") {
                attribute = "href";
            } else if (element.prop('tagName') === "DIV") {
                attribute = "data-ferryt-url";
                element.attr('data-rules-param', true);
                console.log("Parameter rules for ferryt :: running")
            } else {
                attribute = "src";
            }
            var url = element.attr(attribute);
            //obsługa pustego urla lub samej kotwicy
            if (url === undefined || url === null || url.indexOf('#') === 0) {
                continue;
            }
            //obsługa kotwicy po urlu
            var anchorTag = null;
            if (url.indexOf('#') > 0) {
                var urlParts = url.split('#', 2);
                url = urlParts[0];
                anchorTag = '#' + urlParts[1];
            }
            // Implementacja poszczególnych typów akcji
            switch (actionType.code) {
                case 'add_parameter':
                    var addName = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var addValue = RulesForParameters.getParameterValue(ruleActionArguments[1].ruleActionArgumentValue);
                    if (addValue === 'undefined') {
                        break;
                    }
                    if (url.indexOf('?') === -1) {
                        url = url.concat('?');
                    } else {
                        url = url.concat('&');
                    }
                    url = url.concat(addName + '=' + addValue);
                break;
                case 'remove_parameter':
                    var removeName = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var urlparts= url.split('?');
                    if (urlparts.length >= 2) {
                        var prefix= encodeURIComponent(removeName)+'=';
                        var pars= urlparts[1].split(/[&;]/g);
                        for (var i= pars.length; i-- > 0;) {
                            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                                pars.splice(i, 1);
                            }
                        }
                        if (pars.length === 0) {
                            url = urlparts[0].replace('?', '');
                        } else {
                            url = urlparts[0] + '?' + pars.join('&');
                        }
                    }
                break;
                case 'change_parameter_value':
                    var changeName = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var changeValue = RulesForParameters.getParameterValue(ruleActionArguments[1].ruleActionArgumentValue);

                    var changeNameRegex = new RegExp('(' + changeName + '=).*?(&)');
                    if (url.match(changeNameRegex) !== null) {
                        url = url.replace(changeNameRegex,'$1' + changeValue + '$2');
                        //jeżeli szukany atrybut jest na końcu to musimy zastosować inny regex
                    } else {
                        changeNameRegex = new RegExp('(' + changeName + '=).*');
                        url = url.replace(changeNameRegex,'$1' + changeValue);
                    }
                break;
                case 'change_parameter_name':
                    var originalName = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var changeName = RulesForParameters.getParameterValue(ruleActionArguments[1].ruleActionArgumentValue);
                    url = url.replace(originalName, changeName);
                break;
                case 'add_string':
                    var appendText = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    url = url.concat(appendText);
                break;
                case 'replace_string':
                    var toReplace = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var replaced = RulesForParameters.getParameterValue(ruleActionArguments[1].ruleActionArgumentValue);
                    url = url.replace(toReplace,replaced);
                break;
                case 'copy_parameter':
                    var parameterName = RulesForParameters.getParameterValue(ruleActionArguments[0].ruleActionArgumentValue);
                    var parameter = RulesForParameters.parameters.filter(function(parameter) {
                        return parameter.key === parameterName;
                    })[0];
                    if (parameter === undefined || parameter === null) {
                        break;
                    }
                    if (url.indexOf('?') === -1) {
                        url = url.concat('?');
                    } else {
                        url = url.concat('&');
                    }
                    url = url.concat(parameter.key + '=' + parameter.value);

                break;
                default:
                    console.log('Wrong Action defined in RulesForParameters Module');
                break;
            }
            if (anchorTag !== null) {
                url = url.concat(anchorTag);
            }
            element.attr(attribute, url);
        }
    },
    /**
     * Obsługa czyszczenia session Storage
     * @param parameterNames parametry w URL/sessionStorage, które uruchamiają wykonanie akcji
     */
    handleSessionStorageCleaning: function(parameterNames) {
        var isInURL = false;
        for (var i = 0; i < parameterNames.length; i++) {
            if (URLParameterManager.getBrowserUrlParameter(parameterNames[i]) != null) {
                isInURL = true;
            }
        }
        if (isInURL) {
            RulesForParameters.setStorageItem("");
            RulesForParameters.parameters = [];
            // Przepisujemy do session storage wszystkie parametry z URL POZA tym czyszczącym sesję
            $.map(window.location.search.substring(1).split("&").filter(function(pair) {
                var notInUrl = true;
                for (var j = 0; j < parameterNames.length; j++) {
                    if (pair.split('=')[0] === parameterNames[j]) {
                        notInUrl = false;
                    }
                }
                return notInUrl;
            }), RulesForParameters.insertToParameterMap);
        }
    },
    handleParameterVariables : function(value) {
        // aktualny adres
        var location = window.location.href.split('?')[0];
        var shortURL = window.location.pathname;

        // typ urządzenia
        var deviceName = 'desktop';
        // To zewnętrzna biblioteka - otaczamy try-catchem aby nic się nie stało jeżeli rzuci jakiś brzydki wyjątek
        try {
            var md = new MobileDetect(window.navigator.userAgent);
            if (md.phone() !== null) {
                deviceName = 'phone';
            }
            if (md.tablet() !== null) {
                deviceName = 'tablet';
            }
        } catch(exception) {
            deviceName = 'desktop';
        }
        return value.replace('$currentURL', location)
                        .replace('$device', deviceName)
                        .replace('$cmb1', RulesForParameters.timeResultForCmb1())
                        .replace('$cmb2', RulesForParameters.timeResultForCmb2())
                        .replace('$shortfrom', shortURL);

    },
    // Funkcja filtrująca parametry po wskazanej nazwie
    getParametersWithName: function(parameter, paramToFind) {
        return parameter.key === paramToFind;
    },
    // Pobranie wartości parametru (podmienia wartość znacznika $ParValue(attribute_name))
    getParameterValue : function(value) {
        value = RulesForParameters.handleParameterVariables(value);
        // szukaj ciągu znaków $ParValue() z dowolnym tekstem w środku, g - wyszukuje wszystkie wystąpienia
        var pattern = /\$ParValue\((.*)\)/g;
        var foundParValue = value.match(pattern);
        var filterParamsByName = function(parameter) {
            return RulesForParameters.getParametersWithName(parameter, paramToFind);
        };
        if (foundParValue !== null && foundParValue !== undefined) {
            for (i = 0; i < foundParValue.length; i++) {
                // nazwa parametru znajduje się wewnątrz nawiasów, resztę wyrażenia usuwamy
                var paramToFind = foundParValue[0].replace('$ParValue(', '').replace(')', '');
                // szukamy parametru w sessionStorage
                var foundedParams = RulesForParameters.parameters.filter(filterParamsByName);
                 if (foundedParams.length > 0) {
                      return value.replace(pattern, foundedParams[0].value);
                 } else {
                     return "";
                 }
            }
        }
        return value;
    },

    // Pobranie elementu, dla którego zdefiniowana jest dana reguła
    getElements : function(elementJson, ruleId) {
        var elements = JSON.parse(elementJson);
        var foundElements = new Array();
        for (var j = 0; j < elements.length; j++) {
            var element = elements[j];
            var elementTypeCode = RulesForParameters.findInDictById(RulesForParameters.ruleElementTypes, element.ruleElementTypeId);
            if (elementTypeCode.code === "sessionStorage") {
                RulesForParameters.pushElement(RulesForParameters.SESSION_STORAGE_CODE, foundElements, ruleId);
                continue;
            }
            if (elementTypeCode.code === "ferrytForm") {
                const ferrytElementValue = element.value;
                const multimetersSymbol = element.value.includes('*');
                const dataFerrytValue = "data-ferryt-value";
                if (multimetersSymbol) {
                    const ferrytElementValueSymbol = element.value.replaceAll('*', '')
                    var elementsFerryt = document.querySelectorAll(`[${dataFerrytValue}*='${ferrytElementValueSymbol}']`);
                } else {
                    var elementsFerryt = document.querySelectorAll(`[${dataFerrytValue}='${ferrytElementValue}']`);
                }
                elementsFerryt.forEach(elementsFerryt =>{
                    RulesForParameters.pushElement(elementsFerryt, foundElements, ruleId);
                })
                continue;
            }
            var attributeTypeCode = RulesForParameters.findInDictById(RulesForParameters.ruleAttributeTypes, element.ruleAttributeTypeId);
            if(element.value !== "*") {
                elementValues = element.value.split('*');
            } else {
                elementValues=[];
            }
            if (elementValues.length > 1) {
                var selectorValue = '';
                for (var i = 0; i < elementValues.length; i++) {
                    if (elementValues[i].length === 0) {
                        continue;
                    }
                    var selectorOperator;
                    if (i === 0) {
                        selectorOperator = '^="';
                    } else if (i === elementValues.length - 1) {
                        selectorOperator = '$="';
                    } else {
                        selectorOperator = '*="';
                    }
                    selectorValue = selectorValue.concat(elementTypeCode.code + '[' + attributeTypeCode.code + selectorOperator + elementValues[i]+ '"]');
                }
                $(selectorValue).each(function() {
                    RulesForParameters.pushElement(this, foundElements, ruleId);
                });
            } else {
                if (element.value === '*') {
                    // *
                    $(elementTypeCode.code).each(function() {
                        RulesForParameters.pushElement(this, foundElements, ruleId);
                    });
                } else {
                    //pozostałe
                    $(elementTypeCode.code + '[' + attributeTypeCode.code + '="' + element.value + '"]').each(function() {
                        RulesForParameters.pushElement(this, foundElements, ruleId);
                    });
                }
            }
        }
        return foundElements;
    },
    // Analizowanie Jsona ze słownikami i zapisywanie go do zmiennych lokalnych
    prepareDicts : function(dictsJson) {
        var dicts = JSON.parse(dictsJson);
        RulesForParameters.ruleElementTypes = dicts.ruleElementTypes;
        RulesForParameters.ruleAttributeTypes = dicts.ruleAttributeTypes;
        RulesForParameters.ruleRelationalOperators = dicts.ruleRelationalOperators;
        RulesForParameters.ruleActionTypes = dicts.ruleActionTypes;
        RulesForParameters.ruleActionTypeArguments = dicts.ruleActionTypeArguments;
    },
    // Funkcja pomocnicza - wyszukiwanie w słowniku po ID
    findInDictById : function(dict, id) {
        return dict.filter(function(entry) {
            return entry.id === id;
        })[0];
    },
    // Funkcja pomocnicza - wyszukiwanie w słowniku po Code
    findInDictByCode : function(dict, code) {
        return dict.filter(function(entry) {
            return entry.code === code;
        })[0];
    },
    // Funkcja pomocnicza - ustawia zadaną wartość w storage
    setStorageItem : function(value) {
        if (RulesForParameters.isStorageSupported()) {
            sessionStorage[RulesForParameters.STORAGE_NAME] = value;
        } else {
            $.cookie(RulesForParameters.STORAGE_NAME, value);
        }
    },
    // Funkcja pomocnicza - pobiera ustawioną wartość ze storage
    getStorageItem : function() {
        if (RulesForParameters.isStorageSupported()) {
            return sessionStorage[RulesForParameters.STORAGE_NAME];
        } else {
            return $.cookie(RulesForParameters.STORAGE_NAME);
        }
    },
    // Funkcja pomocnicza - sprawdza czy przeglądarka wspiera sessionStorage
    isStorageSupported : function() {
        var testKey = 'test', storage = window.sessionStorage;
        if (RulesForParameters.storageSupported === null) {
            try {
                storage.setItem(testKey, '1');
                storage.removeItem(testKey);
                RulesForParameters.storageSupported = true;
            } catch (error) {
                RulesForParameters.storageSupported = false;
            }
        }
        return RulesForParameters.storageSupported;
    },

    pushElement : function(element, foundElements, ruleId) {
        if (typeof RulesForParameters.markedElements[ruleId] === 'undefined') {
            RulesForParameters.markedElements[ruleId] = [element];
            foundElements.push(element);
        } else {
            if ($.inArray(element, RulesForParameters.markedElements[ruleId]) === -1) {
                foundElements.push(element);
                RulesForParameters.markedElements[ruleId].push(element);
            }
        }
    },
    timeResultForCmb1 : function() {
        var todaysDate = null;
        function isIE() {
            var ua = navigator.userAgent;
            return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
        }
        if (isIE()) {
            todaysDate = new Date();
        } else {
            todaysDate = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Warsaw"}));
        }
        var day = todaysDate.getDay();
        var hour = todaysDate.getHours();
        return (day <= 5 && day >= 1 && hour < 20 && hour >= 8)
        || (day === 6 && hour < 18 && hour >= 8);
    },
    timeResultForCmb2 : function() {
        return RulesForParameters.timeResultForCmb1();
    }

};
